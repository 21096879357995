/*** IMPORTS FROM imports-loader ***/
var define = false;

/*
|
| Importing Libs
|------------------
*/
require('@lib/iziModal/js/iziModal.js')($); //désolé
import Swiper from 'swiper/dist/js/swiper.min';
import { TweenMax } from "gsap/TweenMax";
import SplitText from "@lib/gsap-pro/SplitText";
import ScrollTo from "gsap/ScrollToPlugin";
import 'scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap.js';
import 'scrollmagic/scrollmagic/uncompressed/plugins/jquery.ScrollMagic.js';
import 'scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators.js';
import ScrollMagic from 'scrollmagic';
import Scrollbar from 'smooth-scrollbar';

/*
|
| Importing Components
|-----------------------
*/
import CookieManager from '@components/cookie-manager';
import customGoogleMap from '@components/custom-google-map.js';
import Kira from '@components/kira.js';
import Menu from '@components/menu.js';

/*
|
| Importing Utils
|-------------------
*/
import Router from '@utils/router.js';

/*
|
| Importing App files
|----------------------
*/
import * as app from '@components/global.js';
import main from '@pages/main.js';
import animations from '@pages/animations.js';
import sample from '@pages/sample.js';
import contact from '@pages/contact.js';
import software from '@pages/software.js';
import news from '@pages/news.js';

/*
|
| Routing
|----------
*/
const routes = new Router([
    {
        'file': animations,
        'dependencies': [app, Menu, Kira, ScrollMagic]
    },
	{
		'file': main,
		'dependencies': [app, CookieManager]
    },
	{
		'file': sample,
		'dependencies': [app],
		'resolve': '#page-sample'
    },
  {
    'file': software,
		'dependencies': [Swiper],
		'resolve': '#page-software'
    },
  {
      'file': contact,
      'dependencies': [app, customGoogleMap],
      'resolve': '#page-contact'
    },
    {
        'file': news,
        'dependencies': [app],
        'resolve': '#page-news-archive'
    },
]);

/*
|
| Run
|------
*/
(($) => { routes.load() })(jQuery);

